import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WaxIcon from '../../../images/wax_logo.png';

import OrangeBorderButton from '../../OrangeBorderButton/OrangeBorderButton';

import { BUY, SELL, BOTH, OFFER } from '../../../constants/pool.constants';

function SecondStep({
  currentStep,
  selectedOption,
  selectedCollection,
  show,
  selectedCollectionForSellPool,
  openList,
  setSearchCollectionValue,
  handleSelectCollection,
  handlerOpenList,
  handlerOpenListForSwap,
  handlerOpenListForSellSchema,
  handlerOpenListForSwapSchema,
  selectedSchemaForSellPool,
  openListForSellSchema,
  currentSchemaForSell,
  handlerSelectedSchemaForSell,
  handlerSelectedSchemaForSwapOfferPool,
  handleSelectTemplate,
  handlerOpenListForTemplate,
  selectedTemplate,
  openListForTemplate,
  currentTemplate,
  selectedSchemaForSwapOfferPool,
  setSelectedSchemaForSwapOfferPool,

  setSelectedCollectionForSwapPool,
  selectedCollectionForSwapPool,
  handleSelectCollectionForSwapPool,

  openListForSwap,
  openListForSwapSchema,
  setOpenListForSwapSchema,

  currentSchemaForSwap,

  handlerOpenListForSwapTemplate,
  handlerOpenListForReceiveTemplate,
  setOpenListForSwapTemplate,
  setOpenListForReceiveTemplate,
  openListForSwapTemplate,
  handleSelectSwapTemplate,
  currentSwapTemplate,
  selectedSwapTemplate,
  setSelectedSwapTemplate,
  setSelectedReceiveTemplate,

  selectedReceiveTemplate,
  openListForReceiveTemplate,
  currentReceiveTemplate,
  handleSelectReceiveTemplate,
  handleSelectValueReceiveTemplate,

  setSelectedAmountSwapPool,
  selectedAmountSwapPool,

  setAdditionalPriceOffrePool

}) {

  const allCollections = useSelector((state) => state.allUserCollections);
  const [isEditCollectionSwapMode, setIsEditCollectionSwapMode] = useState(false);
  const [isEditSchemaSwapMode, setIsEditSchemaSwapMode] = useState(false);
  const [isEditTemplateSwapMode, setIsEditTemplateSwapMode] = useState(false);
  const [isEditTemplateReceiveMode, setIsEditTemplateReceiveMode] = useState(false);
  // const allUserCollections = useSelector((state) => state.allUserCollections);


  const handleAmountSwapChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setSelectedAmountSwapPool(selectedValue);
  };

  const handleAddEditButtonClick = () => {
    if (selectedCollectionForSwapPool) {
      setIsEditCollectionSwapMode(!isEditCollectionSwapMode);


    } else {
      setSelectedSchemaForSwapOfferPool(null)
    }
  };

  const handleAddEditSchemaButtonClick = () => {

    if (selectedSchemaForSwapOfferPool) {
      setIsEditSchemaSwapMode(!isEditSchemaSwapMode);
      setOpenListForSwapSchema(false);
    } else {
      setIsEditSchemaSwapMode(false);
    }
  };

  const handleAddEditTemplateButtonClick = () => {

    if (selectedSwapTemplate) {
      setIsEditTemplateSwapMode(!isEditTemplateSwapMode);
      setOpenListForSwapTemplate(false)
    } else {
      // If no collection is selected, perform 'Add' action
      // Add your logic here for the 'Add' functionality
    }
  };

  const handleAddEditReceiveTemplateButtonClick = () => {

    if (selectedReceiveTemplate) {
      setIsEditTemplateReceiveMode(!isEditTemplateReceiveMode);
      setOpenListForReceiveTemplate(false)
    } else {
      // If no collection is selected, perform 'Add' action
      // Add your logic here for the 'Add' functionality
    }
  };

  // const handleAmountChange = (item, newAmount) => {
  //   setSelectedSwapTemplate((prevTemplates) => {
  //     return prevTemplates.map((template) =>
  //       template === item ? { ...template, amount: newAmount } : template
  //     );
  //   });
  // };

  // const handleAmountChangeReceive = (item, newAmount) => {
  //   setSelectedReceiveTemplate((prevTemplates) => {
  //     return prevTemplates.map((template) =>
  //       template === item ? { ...template, amount: newAmount } : template
  //     );
  //   });
  // };


  return (
    <div
      className={`create-pool-stepper-second ${currentStep !== 2 ? 'hidden' : ''
        }`}
    >
      {selectedOption === BUY && (
        <div className={'create-pool-stepper-second_wrapper'}>
          <div className="left">
            <p>I want to …</p>
            <p>Deposit</p>
            <OrangeBorderButton width={180} height={44}>
              <img className={'wax-logo'} src={WaxIcon} alt="" />
              Wax
            </OrangeBorderButton>
          </div>
          <div className="right">
            <p>and ...</p>
            <p>Receive</p>
            <OrangeBorderButton onClick={show} width={180} height={44}>
              {selectedCollection ? (
                <>
                  {selectedCollection.data.img &&
                    selectedCollection.data.img?.startsWith('Qm') ? (
                    <img
                      src={`https://atomichub-ipfs.com/ipfs/${selectedCollection.data.img}`}
                      alt=""
                    />
                  ) : (
                    <img src={selectedCollection.data.img} alt="" />
                  )}
                  {selectedCollection?.collection_name}
                </>
              ) : (
                'Select NFT'
              )}{' '}
              <span className={'arrow'}> &gt; </span>
            </OrangeBorderButton>
          </div>
        </div>
      )}
      {selectedOption === SELL && (
        <div className={'create-pool-stepper-third_wrapper'}>
          <div className="left">
            <p>I want to …</p>
            <p>Deposit</p>

            <OrangeBorderButton onClick={show} width={180} height={44}>
              {selectedCollectionForSellPool ? (
                <>
                  {selectedCollectionForSellPool?.img &&
                    selectedCollectionForSellPool?.img.startsWith('Qm') ? (
                    <img
                      src={`https://atomichub-ipfs.com/ipfs/${selectedCollectionForSellPool?.img}`}
                      alt=""
                    />
                  ) : (
                    <img src={selectedCollectionForSellPool?.img} alt="" />
                  )}
                  {selectedCollectionForSellPool?.collection_name}
                </>
              ) : (
                'Select NFT'
              )}{' '}
              <span className={'arrow'}> &gt; </span>
            </OrangeBorderButton>
          </div>
          <div className="right">
            <p>and ...</p>
            <p>Receive</p>
            <OrangeBorderButton width={180} height={44}>
              <img className={'wax-logo'} src={WaxIcon} alt="" />
              Wax
            </OrangeBorderButton>
          </div>
        </div>
      )}
      {selectedOption === BOTH && (
        <div className={'create-pool-stepper-second_wrapper'}>
          <div className="left">
            <p>I want to …</p>
            <p>Deposit</p>
            <OrangeBorderButton width={180} height={44}>
              <img className={'wax-logo'} src={WaxIcon} alt="" />
              Wax
            </OrangeBorderButton>
          </div>
          <div className="right">
            <p>I want to …</p>
            <p>Deposit</p>
            <OrangeBorderButton onClick={show} width={180} height={44}>
              {selectedCollection ? (
                <>
                  {selectedCollection.data.img &&
                    selectedCollection.data.img?.startsWith('Qm') ? (
                    <img
                      src={`https://atomichub-ipfs.com/ipfs/${selectedCollection.data.img}`}
                      alt=""
                    />
                  ) : (
                    <img src={selectedCollection.data.img} alt="" />
                  )}
                  {selectedCollection?.collection_name}
                </>
              ) : (
                'Select NFT'
              )}{' '}
              <span className={'arrow'}> &gt; </span>
            </OrangeBorderButton>
          </div>
        </div>
      )}
      {selectedOption === OFFER && (
        <div className={'create-pool-stepper-second_wrapper-offer'}>
          <div className="left-offer">
            <div className="left-offer_container">
              <h6 className="left-offer-title">
                I want to swap
              </h6>
              <div className="collections-block">
                <div className="content-collections">
                  <p className='top-title'>Collection *</p>
                  {!isEditCollectionSwapMode ?
                    <OrangeBorderButton
                      onClick={handlerOpenListForSwap}
                      width={243}
                      height={40}
                    >
                      {!selectedCollectionForSwapPool ? (
                        '+ Choose collection'
                      ) : (
                        <div className={'coll-block'}>
                          {selectedCollectionForSwapPool.data.img &&
                            selectedCollectionForSwapPool.data.img?.startsWith('Qm') ? (
                            <img
                              src={`https://atomichub-ipfs.com/ipfs/${selectedCollectionForSwapPool.data.img}`}
                              alt=""
                            />
                          ) : (
                            <img src={selectedCollectionForSwapPool.data.img} alt="" />
                          )}
                          {selectedCollectionForSwapPool?.collection_name}
                        </div>
                      )}
                    </OrangeBorderButton>
                    :
                    <div className={'coll-block-collection'}>
                      {selectedCollectionForSwapPool.data.img &&
                        selectedCollectionForSwapPool.data.img?.startsWith('Qm') ? (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${selectedCollectionForSwapPool.data.img}`}
                          alt=""
                        />
                      ) : (
                        <img src={selectedCollectionForSwapPool.data.img} alt="" />
                      )}
                      <p>{selectedCollectionForSwapPool?.collection_name}</p>
                    </div>
                  }

                  {openListForSwap && (
                    <div className="dropdown-menu">
                      <div className="search-block">
                        <input
                          type="text"
                          className={'search-collection'}
                          placeholder={'Search collection'}
                          onChange={(e) => setSearchCollectionValue(e.target.value)}
                        />
                      </div>

                      {allCollections.map((item, i) => (
                        <div
                          key={i}
                          className="item"
                          onClick={() => handleSelectCollectionForSwapPool(item)}
                        >
                          {item.data.img && item.data.img?.startsWith('Qm') ? (
                            <img
                              src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`}
                              alt=""
                            />
                          ) : (
                            <img src={item.data.img} alt="" />
                          )}

                          <div className={'about'}>
                            <p>{item.collection_name}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="add-edit-btn">
                  <button onClick={handleAddEditButtonClick}>
                    {isEditCollectionSwapMode ? 'Edit' : 'Add'}
                  </button>
                </div>
              </div>
              {isEditCollectionSwapMode &&
                <div className="collections-schema">
                  <div className="content-schema">
                    <p className='top-title'>Schema *</p>
                    {!isEditSchemaSwapMode ?
                      <OrangeBorderButton
                        onClick={handlerOpenListForSwapSchema}
                        width={243}
                        height={40}
                      >
                        {!selectedSchemaForSwapOfferPool ? (
                          '+ Schema'
                        ) : (
                          <>
                            <p> {selectedSchemaForSwapOfferPool}</p>
                          </>
                        )}
                      </OrangeBorderButton>
                      :
                      <p className='coll-block-schema'> {selectedSchemaForSwapOfferPool}</p>
                    }

                    {openListForSwapSchema && (
                      <div className="dropdown-menu">
                        {currentSchemaForSwap.map((item) => (
                          <div
                            className="item"
                            onClick={() =>
                              handlerSelectedSchemaForSwapOfferPool(item.schema_name)
                            }
                          >
                            <div className="about">
                              <p>{item.schema_name}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="add-edit-btn">
                    <button onClick={handleAddEditSchemaButtonClick}>
                      {isEditSchemaSwapMode ? 'Edit' : 'Add'}
                    </button>
                  </div>
                </div>
              }
              {isEditCollectionSwapMode && (
                <div className="collections-template">
                  <div className="content-templates">
                    <p className='top-title'>Template (optional, up to 10)</p>
                    {!isEditTemplateSwapMode ?
                      <OrangeBorderButton
                        width={243}
                        height={40}
                        onClick={handlerOpenListForSwapTemplate}
                      >
                        {!selectedSwapTemplate || !selectedSwapTemplate.length
                          ? '+ Template (optional)'
                          : `Added ${selectedSwapTemplate.length} templates`}
                      </OrangeBorderButton>
                      :
                      <div className='coll-block-templates'>
                        {selectedSwapTemplate.map((item, index) => (
                          <div className="templates-selected-item" key={index}>
                            <div className="id-templates">
                              {item && item.immutable_data && item.immutable_data.img &&
                                <div className="template-photo">
                                  {item.immutable_data.img &&
                                    item.immutable_data.img?.startsWith('Qm') ? (
                                    <img
                                      src={`https://atomichub-ipfs.com/ipfs/${item.immutable_data.img}`}
                                      alt=""
                                    />
                                  ) : (
                                    <img src={item.immutable_data.img} alt="" />
                                  )}
                                </div>
                              }
                              <div className="template-info">
                                <div className="template-name">{item.type}</div>
                                <div className="template-id">#{item.template_id}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                    }

                    {openListForSwapTemplate && (
                      <div className="dropdown-menu">
                        {currentSwapTemplate.map((item) => (
                          <div className="item-templates" key={item.template_id}>
                            <label>
                              <div className="about-templates">
                                <p>{item.name}</p>
                                <p>#{item.template_id}</p>
                              </div>
                              <input
                                type="checkbox"
                                checked={selectedSwapTemplate.some(template => template.template_id === item.template_id)}
                                onChange={() => handleSelectSwapTemplate(item)}
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="add-edit-btn">
                    <button onClick={handleAddEditTemplateButtonClick}>
                      {isEditTemplateSwapMode ? 'Edit' : 'Add'}
                    </button>

                    <div className="amount-templates">
                              <label>Amount</label>
                              <select 
                                value={selectedAmountSwapPool}
                                className='select-template-amount'
                                onChange={(e) => handleAmountSwapChange(e)}
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                      </div>
                  </div>
                </div>
              )}

            </div>
          </div>

          <div className="right-offer">
            <div className="right-offer_container">
              <h6 className="right-offer-title">
                I want to receive
              </h6>
              <div className="collections-block right">
                <div className="content-collections">
                  <p className='top-title'>Collection</p>
                  {!isEditCollectionSwapMode ?
                    <OrangeBorderButton
                      onClick={null}
                      width={243}
                      height={40}
                    >
                      {!selectedCollectionForSwapPool ? (
                        '+ Choose collection'
                      ) : (
                        <div className={'coll-block'}>
                          {selectedCollectionForSwapPool.data.img &&
                            selectedCollectionForSwapPool.data.img?.startsWith('Qm') ? (
                            <img
                              src={`https://atomichub-ipfs.com/ipfs/${selectedCollectionForSwapPool.data.img}`}
                              alt=""
                            />
                          ) : (
                            <img src={selectedCollectionForSwapPool.data.img} alt="" />
                          )}
                          {selectedCollectionForSwapPool?.collection_name}
                        </div>
                      )}

                    </OrangeBorderButton>

                    :
                    <div className={'coll-block-collection'}>
                      {selectedCollectionForSwapPool.data.img &&
                        selectedCollectionForSwapPool.data.img?.startsWith('Qm') ? (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${selectedCollectionForSwapPool.data.img}`}
                          alt=""
                        />
                      ) : (
                        <img src={selectedCollectionForSwapPool.data.img} alt="" />
                      )}
                      <p>{selectedCollectionForSwapPool?.collection_name}</p>
                    </div>
                  }

                </div>
              </div>
              {isEditCollectionSwapMode &&
                <div className="collections-schema">
                  <div className="content-schema">
                    <p className='top-title'>Schema *</p>
                    {!isEditSchemaSwapMode ?
                      <OrangeBorderButton
                        onClick={handlerOpenListForSwapSchema}
                        width={243}
                        height={40}
                      >
                        {!selectedSchemaForSwapOfferPool ? (
                          '+ Schema'
                        ) : (
                          <>
                            <p> {selectedSchemaForSwapOfferPool}</p>
                          </>
                        )}
                      </OrangeBorderButton>
                      :
                      <p className='coll-block-schema'> {selectedSchemaForSwapOfferPool}</p>
                    }

                    {/* {openListForSwapSchema && (
                   <div className="dropdown-menu">
                     {currentSchemaForSwap.map((item) => (
                       <div
                         className="item"
                         onClick={() =>
                           handlerSelectedSchemaForSwapOfferPool(item.schema_name)
                         }
                       >
                         <div className="about">
                           <p>{item.schema_name}</p>
                         </div>
                       </div>
                     ))}
                   </div>
                 )} */}
                  </div>
                  {/* <div className="add-edit-btn">
                 <button onClick={handleAddEditSchemaButtonClick}>
                   {isEditSchemaSwapMode ? 'Edit' : 'Add'}
                 </button>
               </div> */}
                </div>
              }
              {isEditCollectionSwapMode &&
                <div className="collections-template">
                  <div className="content-templates">
                    <p className='top-title'>Template (up to 10) *</p>
                    {!isEditTemplateReceiveMode ?
                      <OrangeBorderButton
                        width={243}
                        height={40}
                        onClick={handlerOpenListForReceiveTemplate}
                      >
                        {!selectedReceiveTemplate || !selectedReceiveTemplate.length
                          ? '+ Template (optional)'
                          : `Added ${selectedReceiveTemplate.length} templates`}
                      </OrangeBorderButton>
                      :
                      <div className='coll-block-templates'>
                        {selectedReceiveTemplate.map((item, index) => (
                          <div className="templates-selected-item" key={index}>
                            <div className="amount-templates">
                              <label htmlFor={`amountInput-${index}`}>Amount</label>
                              <select
                                defaultValue={1}
                                className='select-template-amount'
                                onChange={(e) => handleSelectValueReceiveTemplate(item.key, parseInt(e.target.value, 10))}
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                            <div className="id-templates">
                              #{item.key}
                            </div>
                          </div>
                        ))}
                      </div>

                    }

                    {openListForReceiveTemplate && (
                      <div className="dropdown-menu">
                        {currentReceiveTemplate.map((item) => (
                          <div className="item-templates" key={item.template_id}>
                            <label>
                              <div className="about-templates">
                                <p>{item.name}</p>
                                <p>#{item.template_id}</p>
                              </div>
                              <input
                                type="checkbox"
                                checked={selectedReceiveTemplate.some(template => template.key === item.template_id)}
                                onChange={() => handleSelectReceiveTemplate(item.template_id)}
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="add-edit-btn">
                    <button onClick={handleAddEditReceiveTemplateButtonClick}>
                      {isEditTemplateReceiveMode ? 'Edit' : 'Add'}
                    </button>
                  </div>
                </div>
              }
              {isEditCollectionSwapMode &&
                <div className="swap-price">
                  <p>and (optional)</p>
                  <input type="number" placeholder='Set swap price' onChange={(e) => setAdditionalPriceOffrePool(e.target.value)} />
                  <div className="price-token">
                    <img src={WaxIcon} alt="" />
                    <span>WAX</span>
                  </div>

                </div>
              }

            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SecondStep;
