import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import WaxIcon from '../../images/wax_logo.png';
import LoaderData from '../LoaderData/LoaderData';
import './PoolItem.scss';

const PoolItemImage = ({ src }) => (
  <div className="collection-image-s">
    {src && src.startsWith && src.startsWith('Qm') ? (
      <img src={`https://atomichub-ipfs.com/ipfs/${src}`} alt="" />
    ) : (
      <img src={src} alt="" />
    )}
  </div>
);

const NFTImage = ({ src }) => (
  <div className="nfts-content-card_img">
    {src && src.startsWith && src.startsWith('Qm') ? (
      <img src={`https://atomichub-ipfs.com/ipfs/${src}`} alt="" />
    ) : (
      <img src={src} alt="" />
    )}
  </div>
);


const PoolItem = ({
  filteredSellPools,
  filteredBuyPools,
  filteredTradePools,
  filteredOfferPools,
  collectionsName,
  checked,
  searchCollection,
  selectedFilterCollection,
  selectedFilterSchema,
}) => {
  const [pools, setPools] = useState([]);

  useEffect(() => {
    const sellP = filteredSellPools.filter(pool => !checked || (pool.pool_nfts?.length ?? 0) > 0);
    const buyP = filteredBuyPools.filter(pool => !checked || pool.pool_asset !== '0.00000000 WAX');
    const tradeP = filteredTradePools.filter(pool => !checked || pool.pool_asset !== '0.00000000 WAX');
    const offerP = filteredOfferPools.filter(pool => (!checked || (pool.pool_nfts?.length !== 0 && pool.owner_receiving_amount !== 0)));

    setPools([...sellP, ...buyP, ...tradeP, ...offerP]);
  }, [filteredSellPools, filteredBuyPools, filteredTradePools, filteredOfferPools, checked]);

  const formatPrice = price => parseFloat(price?.split(' ')[0]).toFixed(2);

  return (
    <>
      {pools.length === 0 ? (
        <div className="loader-data">
          <LoaderData />
        </div>
      ) : (
        pools.filter(item => !selectedFilterSchema || !selectedFilterSchema.length || item?.schemas?.includes(...selectedFilterSchema))
          .filter(item => !searchCollection || item.NFTs_collection?.collection_name.includes(searchCollection))
          .filter(item => !selectedFilterCollection || selectedFilterCollection === 'all' || item.NFTs_collection?.collection_name.includes(selectedFilterCollection))
          .map((item, index) => {
            const poolType = filteredBuyPools.includes(item)
              ? 'buy'
              : filteredSellPools.includes(item)
              ? 'sell'
              : filteredTradePools.includes(item)
              ? 'trade'
              : filteredOfferPools.includes(item)
              ? 'offer'
              : '';

            const itemType = {
              buy: 'Buy',
              sell: 'Sell',
              trade: 'Trade',
              offer: 'NFT-2-NFT',
            }[poolType];

            const poolLink = `/collections/${collectionsName ? collectionsName : item?.NFTs_collection?.collection_name}/${poolType}/${item.pool_id}`;

            return (
              <Link key={index} to={poolLink}>
                <div className="item">
                  <div className="item-wrapper">
                    <div className="top">
                      <div className="collection_name">
                        <PoolItemImage src={item?.NFTs_collection?.img} />
                        {item?.NFTs_collection?.collection_name}
                      </div>
                      <div className="type-pool">
                        <p className={`item-type ${poolType}`}>{itemType}</p>
                      </div>
                    </div>
                    {itemType === 'Buy' && (
                      <div className="nfts-content">
                        <PoolItemImage src={item.NFTs_collection?.img} />
                      </div>
                    )}
                    {(itemType === 'Sell' || itemType === 'Trade' || itemType === 'NFT-2-NFT') && (
                      <div className="nfts-content-card">
                        {item?.pool_nfts && item?.pool_nfts.slice(0, 3).map((nft, index) => (
                          <NFTImage key={index} src={nft?.data?.img} />
                        ))}
                      </div>
                    )}
                    <div className="container">
                      <div className="container-wrapper">
                        <div className="price">
                          <div className="price-left">
                            <p className={`price-left-name ${poolType}`}>
                              {itemType === 'Trade' ? (
                                'Sell price'
                              ) : itemType === 'NFT-2-NFT' ? (
                                'Rules'
                              ) : (
                                `${itemType} price`
                              )}
                            </p>
                            <div className="price-left_top_amount">
                              {itemType !== 'NFT-2-NFT' ? (
                                <>
                                  <img src={WaxIcon} alt="" />
                                  <p className="price-left-amount">{formatPrice(item?.floor_price)}</p>
                                </>
                              ) : (
                                <p className="price-left-amount">{item?.pool_nfts?.length} NFT</p>
                              )}
                            </div>
                          </div>
                          <div className="price-right">
                            {itemType === 'Trade' && (
                              <>
                                <p className={`price-right-name`}>Buy price</p>
                                <div className="price-right_top_amount">
                                  <img src={WaxIcon} alt="" />
                                  <p className="price-right-amount">{formatPrice(item?.pool_asset)}</p>
                                </div>
                              </>
                            )}
                            {itemType === 'NFT-2-NFT' && (
                              <div className="price-right_top_amount">
                                <p className="price-right-amount n2n">{item.owner_receiving_amount} NFT</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="delta-balance">
                          <div className="balance">
                            <p className="balance-p">Balance</p>
                            <div className="balance-wax">
                              {itemType === 'Buy' && (
                                <>
                                  <img src={WaxIcon} alt="" />
                                  <p className="balance-amount">{formatPrice(item?.pool_asset)}</p>
                                </>
                              )}
                              {itemType === 'Sell' && itemType !== 'Offer' && (
                                <p className="balance-amount">{item?.pool_nfts ? item?.pool_nfts.length : 0}</p>
                              )}
                              {itemType === 'Trade' && itemType !== 'Offer' && (
                                <p className="balance-amount">
                                  <img src={WaxIcon} alt="" />
                                  {formatPrice(item?.pool_asset)} | {item?.pool_nfts ? item?.pool_nfts.length : 0}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="delta">
                            <p className="delta-p">Owner</p>
                            <p className="delta-amount">{item?.pool_owner}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })
      )}
    </>
  );
};

export default PoolItem;
