import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './FilteredTable.scss';

const FilteredTable = ({ allCollections, allPools }) => {
  const [sortKey, setSortKey] = useState(null);
  const [sortOrders, setSortOrders] = useState({
    name: 'asc',
    pools: 'asc',
    sales: 'asc',
    volume: 'asc',
  });

  const handleSort = (key) => {
    setSortKey(key);
    setSortOrders((prevSortOrders) => ({
      ...prevSortOrders,
      [key]: prevSortOrders[key] === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedCollections = allCollections.sort((a, b) => {
    const order = sortOrders[sortKey];
    if (sortKey === 'name') {
      return (a.collection_name.localeCompare(b.collection_name)) * (order === 'asc' ? 1 : -1);
    } else if (sortKey === 'pools') {
      return (a.poolCount - b.poolCount) * (order === 'asc' ? 1 : -1);
    } else if (sortKey === 'sales') {
      return (a.sales - b.sales) * (order === 'asc' ? 1 : -1);
    } else if (sortKey === 'volume') {
      return (a.volume - b.volume) * (order === 'asc' ? 1 : -1);
    }
    return 0;
  });

  const filteredCollections = sortedCollections.map((item) => {
    const collectionWithPools = allPools.filter((pool) => {
      // console.log(pool?.NFTs_collection?.collection_name);
     return pool?.NFTs_collection?.collection_name === item?.collection_name
    });
    let poolCount = collectionWithPools.length;

    // console.log(item?.collection_name);

    collectionWithPools.forEach((pool) => {
      if (Array.isArray(pool.pool_nfts) && pool.pool_nfts.length === 0) {
        poolCount -= 1;
      }

      if (parseFloat(pool.pool_asset) < parseFloat(pool.floor_price)) {
        poolCount -= 1;
      }
    });

    return { ...item, poolCount };
  });

  return (
    <div className="filter-table">
      <div className="filter">
        {['name', 'pools', 'sales', 'volume'].map((key) => (
          <div key={key} className={key} onClick={() => handleSort(key)}>
            <p>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
            <div className="arrows">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="6"
                viewBox="0 0 12 6"
              >
                <path
                  id="Polygon_4"
                  data-name="Polygon 4"
                  d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                  fill={sortKey === key && sortOrders[key] === 'asc' ? '#fff' : '#8e8e8e'}
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="6"
                viewBox="0 0 12 6"
              >
                <path
                  id="Polygon_5"
                  data-name="Polygon 5"
                  d="M5.646.354a.5.5,0,0,1,.707,0l4.793,4.793A.5.5,0,0,1,10.793,6H1.207a.5.5,0,0,1-.354-.854Z"
                  transform="translate(12 6) rotate(180)"
                  fill={sortKey === key && sortOrders[key] === 'desc' ? '#fff' : '#8e8e8e'}
                />
              </svg>
            </div>
          </div>
        ))}
      </div>
      {filteredCollections.map((item, index) => (
        <Link key={index} to={item.collection_name}>
          <div className="filter-table_item">
            <div className="name">
              <div className="avatar">
                {item.data.img && (
                  <img src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`} alt="" />
                )}
              </div>
              <p>{item.collection_name}</p>
            </div>
            <div className="pools">
              <p>{item.poolCount}</p>
            </div>
            <div className="floor">
              <p>{item.sales}</p>
            </div>
            <div className="volume">
              <p>{(item.volume / 100000000).toFixed(0)} WAX</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default FilteredTable;
