import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';

import {
  getMyNFTs,
  getSellPoolsByCollections,
  withdrawNfts,
} from '../../services/wax.services';

import SliderS from '../../components/Slider/Slider';

import { LinearDiagramForSellPool } from '../../components/LinearDiagramForSellPool/LinearDiagramForSellPool';
import WaxLogo from '../../images/wax_logo2.png';
import ArrowIcon from '../../images/arrow2.png';
import ViewIcon from '../../images/view.png';
import LogoIcon from '../../images/logo@2x.png';
import { ExponentialDiagramForSellPool } from '../../components/ExponentialDiagramForSellPool/ExponentialDiagramForSellPool';
import LoaderData from '../../components/LoaderData/LoaderData';

import { getDataFromAtomicApi, shortenText } from '../../helpers';
import { toast } from 'react-toastify';

import './PoolPageSell.scss';
import RodalDepositPool from '../../components/Modal/RodalDepositPool/RodalDepositPool';
import { toastOptions } from '../../helpers/toast.helper';

const PoolPageSell = () => {
  const { name, id } = useParams();
  const navigate = useNavigate();
  const { activeUser } = useContext(UALContext);

  const [currentPool, setCurrentPool] = useState(null);
  const [delta, setDelta] = useState(0);

  // const [isEditing, setIsEditing] = useState(false);
  // const [editedPool, setEditedPool] = useState(null);
  // const [editedDelta, setEditedDelta] = useState(null);

  const [sliderValue, setSliderValue] = useState(0);

  const [price, setPrice] = useState(0);
  const [priceExpon, setPriceExpon] = useState(0);

  const [isEditingDeposite, setIsEditingDeposite] = useState(false);
  const [isEditingWithdraw, setIsEditingWithdraw] = useState(false);

  const [currentDelta, setCurrentDelta] = useState('Linear');
  const [NFTonSale, setNFTonSale] = useState([]);

  // const [allElementsLoaded, setAllElementsLoaded] = useState(false);
  // const [loadedElementsCount, setLoadedElementsCount] = useState(6);
  const [collectionImg, setCollectionImg] = useState('');

  const [selectedItemToWithdraw, setSelectedItemToWithdraw] = useState([]);

  const [visible, setVisible] = useState(false);
  const [myNFTs, setMyNFTs] = useState([]);

  const [selectedNftToDeposit, setSelectedNftToDeposit] = useState([]);

  useEffect(() => {
    getMyNFTs(activeUser?.accountName, name)
      .then((data) => {
        setMyNFTs(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [activeUser, name]);

  const handleSelectItem = (item) => {
    if (selectedNftToDeposit.includes(item)) {
      setSelectedNftToDeposit((prevSelectedNftToDeposit) =>
        prevSelectedNftToDeposit.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedNftToDeposit((prevSelectedNftToDeposit) => [
        ...prevSelectedNftToDeposit,
        item,
      ]);
    }
  };

  const toggleEditing = (type) => {
    if (type === 'deposit') {
      setVisible(true);
      setIsEditingDeposite(!isEditingDeposite);
    }

    if (type === 'withdraw') setIsEditingWithdraw(!isEditingWithdraw);
  };

  const hide = () => {
    setVisible(false);
  };

  const handleToWithdraw = (item) => {
    const index = selectedItemToWithdraw.indexOf(item);
    if (index === -1) {
      setSelectedItemToWithdraw([...selectedItemToWithdraw, item]);
    } else {
      const updatedItems = [...selectedItemToWithdraw];
      updatedItems.splice(index, 1);
      setSelectedItemToWithdraw(updatedItems);
    }
  };

  const handleSave = async () => {
    try {
      if (isEditingDeposite) {
        setIsEditingDeposite(false);
      }

      if (isEditingWithdraw && selectedItemToWithdraw.length) {
        await withdrawNfts({ activeUser, selectedItemToWithdraw, id });
        toast.success('Success', toastOptions);

        setTimeout(async () => {
          const data = await getSellPoolsByCollections();
          const pool = data.find((item) => item.pool_id === parseInt(id));

          setCurrentPool(pool);
          setNFTonSale(pool.pool_nfts);

          setSelectedItemToWithdraw([]);
        }, 1000);
      }
    } catch (error) {
      const errorMessage = error.message.replace(
        'assertion failure with message: ',
        ''
      );
      toast.error(errorMessage, toastOptions);
      console.error(error);
    } finally {
      setIsEditingWithdraw(false);
    }
  };

  const handleClickBack = () => {
    navigate(`/collections/${name}`);
  };

  const handleClickDirect = () => {
    navigate(`/collections/${name}/swap/sell/${id}`);
  };

  useEffect(() => {
    const fetchPool = async () => {
      await getSellPoolsByCollections()
        .then((data) => {
          const pool = data.find((item) => item.pool_id === parseInt(id));

          setCurrentPool(pool);
          setNFTonSale(pool.pool_nfts);

          if (
            pool.linear_delta === 0 &&
            pool.exponential_delta === '0.00000000000000000'
          ) {
            setCurrentDelta('Linear');
          } else if (pool.linear_delta !== 0) {
            setCurrentDelta('Linear');
          } else {
            setCurrentDelta('Exponential');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchPool();
  }, [id]);

  useEffect(() => {
    if (sliderValue === 0) {
      setPriceExpon(0);
    } else if (sliderValue === 1) {
      setPriceExpon(Number(currentPool?.floor_price.split(' ')[0]));
    } else {
      let price = Number(currentPool?.floor_price.split(' ')[0]);
      let totalPrice = price;
      for (let i = 2; i <= Number(sliderValue); i++) {
        price += price * (delta / 100);
        totalPrice += price;
      }
      setPriceExpon(totalPrice);
    }
  }, [currentPool, sliderValue, delta]);

  useEffect(() => {
    let price = 0;
    let currentDelta = Number(delta);

    if (sliderValue > 0) {
      price = Number(currentPool?.floor_price.split(' ')[0]);
      const calculatePrice = (deltaSign) => {
        for (let i = 2; i <= Number(sliderValue); i++) {
          price +=
            Number(currentPool?.floor_price.split(' ')[0]) +
            deltaSign * currentDelta;
          currentDelta += Number(delta);
        }
        setPrice(price);
      };
      calculatePrice(1);
    } else {
      setPrice(0);
    }
  }, [currentPool, sliderValue, delta]);

  useEffect(() => {
    if (!currentPool) return;
    if (currentPool.linear_delta > 0) {
      setDelta(currentPool.linear_delta / Math.pow(10, 8));
    } else if (currentPool.exponential_delta !== '0.00000000000000000') {
      setDelta(parseFloat(currentPool.exponential_delta));
    } else {
      setDelta(0);
    }
  }, [currentPool]);

  useEffect(() => {
    async function fetchCollectionImage() {
      if (currentPool?.NFTs_collection.collection_name) {
        try {
          const data = await getDataFromAtomicApi(
            `collections/${currentPool.NFTs_collection.collection_name}`
          );
          setCollectionImg(data.img);
        } catch (error) {
          console.log(error);
        }
      }
    }

    fetchCollectionImage();
  }, [currentPool]);

  // const handleEditClick = () => {
  //     setIsEditing(true);
  //     setEditedPool(currentPool.floor_price);
  //     setEditedDelta(delta);
  // };

  return (
    <div className={'pool-page-section'}>
      <div className="pool-page-section_title">
        <div className="name-collection">
          {collectionImg && (
            <img
              src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
              alt=""
            />
          )}
          <p>{name}</p>
        </div>
        <div className="arrow">
          <img src={ArrowIcon} alt="" />
        </div>
        <div className="wax-title">
          <img src={WaxLogo} alt="" />
          <p>WAX</p>
        </div>
      </div>

      <div className="action-buttons">
        <div className="view-all" onClick={handleClickBack}>
          <div className="view-all-fst">
            <img src={ViewIcon} alt="" />
            <p>View All</p>
          </div>
          <div className="view-all-snd">
            {collectionImg && (
              <img
                src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`}
                alt=""
              />
            )}
            <p>{name}</p>
          </div>
        </div>

        {activeUser &&
          currentPool &&
          currentPool.pool_owner &&
          activeUser.accountName !== currentPool.pool_owner &&
          currentPool?.pool_nfts.length !== 0 && (
            <div className="direct-swap" onClick={handleClickDirect}>
              <img src={LogoIcon} alt="" />
              <p>Buy NFT from Pool</p>
            </div>
          )}
      </div>

      <div className="assets-block-sell">
        <div className="assets">
          <div className="assets_title">
            <h5>Assets</h5>
          </div>

          <div className="deposit-field">
            <div className="wrapper">
              <div className="nfts">
                <p>NFTs</p>
                <p>
                  {currentPool?.pool_nfts.length === 0
                    ? 'Empty pool'
                    : currentPool?.pool_nfts.length}
                </p>
              </div>
              {activeUser &&
                currentPool &&
                currentPool.pool_owner &&
                activeUser.accountName === currentPool.pool_owner && (
                  <div className="deposit-withdraw-btn">
                    <button
                      className="deposit-btn"
                      onClick={() => toggleEditing('deposit')}
                    >
                      Deposit
                    </button>
                    {isEditingWithdraw ? (
                      <button className="save-btn" onClick={handleSave}>
                        Save
                      </button>
                    ) : (
                      <button
                        className="withdraw-btn"
                        onClick={() => toggleEditing('withdraw')}
                      >
                        Withdraw
                      </button>
                    )}
                  </div>
                )}
            </div>
          </div>
          {!isEditingWithdraw ? (
            <div className="nfts-field">
              {!NFTonSale.length ? (
                <LoaderData />
              ) : (
                <>
                  <div className="nfts-field-list">
                    {NFTonSale &&
                      NFTonSale.map((item) => {
                        const imgUrl = item?.data?.img?.startsWith('Qm')
                          ? `https://atomichub-ipfs.com/ipfs/${item?.data?.img}`
                          : item?.data?.img;

                        return (
                          <div
                            className="nfts-field-list_item"
                            key={item?.asset_id}
                          >
                            <div className="nfts-field-list_item-image">
                              <img src={imgUrl} alt="" />
                            </div>
                            <div className="nfts-field-list_item-info">
                              <p>#{item?.asset_id}</p>
                              <h6>{shortenText(item?.data?.name, 15)}</h6>
                            </div>
                          </div>
                        );
                      })}
              
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="nfts-field withdraw">
              {!NFTonSale.length ? (
                <LoaderData />
              ) : (
                <>
                  <div className="nfts-field-list">
                    {NFTonSale &&
                      NFTonSale.map((item) => {
                        const imgUrl = item?.data?.img?.startsWith('Qm')
                          ? `https://atomichub-ipfs.com/ipfs/${item?.data?.img}`
                          : item?.data?.img;

                        return (
                          <div
                            className={`nfts-field-list_item withdraw ${
                              selectedItemToWithdraw.includes(item?.asset_id)
                                ? 'active'
                                : ''
                            }`}
                            key={item?.asset_id}
                            onClick={() => handleToWithdraw(item?.asset_id)}
                          >
                            <div className="nfts-field-list_item-image">
                              <img src={imgUrl} alt="" />
                            </div>
                            <div className="nfts-field-list_item-info">
                              <p>#{item?.asset_id}</p>
                              <h6>{shortenText(item?.data?.name)}</h6>
                            </div>
                          </div>
                        );
                      })}
              
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="about">
          <div className="pricing">
            {/*{activeUser && currentPool && currentPool.pool_owner && activeUser.accountName === currentPool.pool_owner &&*/}
            {/*    <div className="edit">*/}
            {/*            <button onClick={handleEditClick}>Edit</button>*/}
            {/*    </div>*/}
            {/*}*/}

            <div className="pricing_title">
              <h5>Pricing</h5>
            </div>
            <div className="info">
              <div className="current-price">
                <div className="wrapper">
                  <div className="little-title">
                    <p>Current Price</p>
                  </div>
                  <p className={'floor_price'}>{currentPool?.floor_price}</p>
                </div>
              </div>
              <div className="delta">
                <div className="wrapper">
                  <div className="little-title">
                    <p>Delta: {currentDelta}</p>
                  </div>
                  <>
                    {currentDelta === 'Linear' && (
                      <div className={'delta-number'}>
                        <p>{delta} WAX</p>
                      </div>
                    )}
                    {currentDelta === 'Exponential' && (
                      <div className={'delta-number'}>
                        <p>{delta.toFixed(2)}%</p>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="about-owner">
            <div className="about-owner_title">
              <h5>About</h5>
            </div>
            <div className="about-owner_info">
              <p>Pool owner:</p>
              <div className="about-owner_info-address">
                <p>{currentPool?.pool_owner}</p>
              </div>
            </div>
            <div className="about-owner_pool-info">
              <div>
                This pool holds {currentPool?.NFTs_collection?.collection_name}{' '}
                and buys{' '}
                <img className={'mini-wax-logo'} src={WaxLogo} alt="" /> WAX
              </div>
              <div>
                Right now this pool costs{' '}
                <span>{currentPool?.floor_price}</span>
              </div>
              <div>
                Each time this pool sells an NFT, the price will be increased by{' '}
                {currentDelta === 'Linear' && <span>{delta} WAX</span>}{' '}
                {currentDelta === 'Exponential' && (
                  <span>{delta.toFixed(2)}%</span>
                )}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="diagram-block">
        <div className="diagram-block_title">
          <h5>Price Graph</h5>
        </div>
        <div className="amount-slider">
          <h4>Selling {sliderValue} NFTs</h4>
          <SliderS
            setSliderValue={setSliderValue}
            slideValue={sliderValue}
            amount={currentPool?.pool_nfts.length}
          />
          {currentDelta === 'Linear' && (
            <p>will cost you {price.toFixed(8)} WAX</p>
          )}
          {currentDelta === 'Exponential' && (
            <p>will cost you {priceExpon.toFixed(8)} WAX</p>
          )}
        </div>
        <div className="diagram-block-content">
          {currentDelta === 'Linear' && (
            <LinearDiagramForSellPool
              startPrice={currentPool?.floor_price.split(' ')[0]}
              delta={delta}
              amount={currentPool?.pool_nfts.length}
              slideValue={sliderValue}
            />
          )}
          {currentDelta === 'Exponential' && (
            <ExponentialDiagramForSellPool
              startPrice={currentPool?.floor_price.split(' ')[0]}
              delta={delta}
              amount={currentPool?.pool_nfts.length}
              slideValue={sliderValue}
            />
          )}
        </div>
      </div>

      <RodalDepositPool
        visible={visible}
        hide={hide}
        myNFTs={myNFTs}
        setMyNFTs={setMyNFTs}
        handleSelectItem={handleSelectItem}
        selectedNftToDeposit={selectedNftToDeposit}
        setSelectedNftToDeposit={setSelectedNftToDeposit}
        id={id}
        user={activeUser}
        name={name}
        setCurrentPool={setCurrentPool}
        setNFTonSale={setNFTonSale}
        setSelectedItemToWithdraw={setSelectedItemToWithdraw}
      />
    </div>
  );
};

export default PoolPageSell;
