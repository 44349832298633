import React, { useState } from 'react';
import SliderS from '../../Slider/Slider';
import { LinearDiagram } from '../../LinearDiagram/LinearDiagram';

import { BOTH, BUY, OFFER, SELL } from '../../../constants/pool.constants';
import { ExponentialDiagram } from '../../ExponentialDiagram/ExponentialDiagram';
import WaxLogo from '../../../images/wax_logo2.png';
import { LinearDiagramForTrade } from '../../LinearDiagramForTrade/LinearDiagramForTrade';
import { ExponentialDiagramForTrade } from '../../ExponentialDiagramForTrade/ExponentialDiagramForTrade';
import OrangeButton from '../../OrangeButton/OrangeButton';
import { CloseIcon } from '../../../images/SVGElements/closeIcon';

function ThirdStep({
  currentStep,
  selectedOption,
  setStartPrice,
  startPrice,
  setDelta,
  delta,
  selectedCollection,
  setAmount,
  amount,
  setSliderValue,
  slideValue,
  price,
  priceExpon,
  setExponDelta,
  exponDelta,
  priceTotal,
  setPriceExponTotal,
  priceExponTotal,
  setPriceTotal,
  feesAmountForTrade,
  setFeesAmountForTrade,

  amountToBuy,
  setAmountToBuy,
  amountToSell,
  setAmountToSell,

  slideValueToSell,
  setSliderValueToSell,

  setWillCost,
  setWillEarn,
  willCostSum,
  willEarnSum,
  visibility, 
  setVisibility, 
  verifiedAccounts, 
  setVerifiedAccounts
}) {
  const [selectedValue, setSelectedValue] = useState('LINEAR');
  const [accountInput, setAccountInput] = useState('');

  // const [visibility, setVisibility] = useState('Public');
  // const [verifiedAccounts, setVerifiedAccounts] = useState([]);

  const handleVisibilityChange = (selectedVisibility) => {
    setVisibility(selectedVisibility);
    setAccountInput('');
    setVerifiedAccounts([])
  };

  const handleAddAccount = () => {
    if (accountInput.trim() !== '') {
      setVerifiedAccounts((prevAccounts) => [...prevAccounts, accountInput.trim()]);
      setAccountInput('');
    }
  };

  const handleRemoveAccount = (accountToRemove) => {
    setVerifiedAccounts((prevAccounts) => prevAccounts.filter((account) => account !== accountToRemove));
  };


  function handleSelectChange(newValue) {
    if (newValue === 'LINEAR') {
      setExponDelta(0);
      setPriceExponTotal(0);
    } else {
      setPriceTotal(0);
      setDelta(0);
    }
    setSelectedValue(newValue);
  }

  return (
    <div
      className={`create-pool-stepper-third ${currentStep !== 3 ? 'hidden' : ''
        }`}
    >
      {selectedOption === BUY && (
        <>
          <div className="count-block">
            <div className="pool-pricing-block">
              <div className="pool-pricing-block_wrapper">
                <div className="pool-pricing-block_title">
                  <h6>Pool Pricing</h6>
                  <p>Set the initial price and how your pool’s price changes</p>
                </div>
                <div className="start-price">
                  <p>Start price: </p>
                  <input
                    type="number"
                    value={startPrice}
                    onChange={(e) => setStartPrice(e.target.value)}
                  />
                  <div className="coin">
                    <img src={WaxLogo} alt="" />
                    <p>WAX</p>
                  </div>
                </div>
                <div className="bonding">
                  <p>Bonding Curve</p>
                  <select
                    name="delta"
                    value={selectedValue}
                    onChange={(e) => handleSelectChange(e.target.value)}
                  >
                    <option value="LINEAR">Linear</option>
                    <option value="EXPONENTIAL">Exponential</option>
                  </select>
                </div>
                {selectedValue === 'LINEAR' ? (
                  <div className="delta-price">
                    <p>Delta </p>
                    <input
                      type="number"
                      value={delta}
                      onChange={(e) => setDelta(e.target.value)}
                    />
                    <div className="coin">
                      <img src={WaxLogo} alt="" />
                      <p>WAX</p>
                    </div>
                  </div>
                ) : (
                  <div className="delta-price">
                    <p>Delta</p>
                    <input
                      type="number"
                      value={exponDelta}
                      onChange={(e) => setExponDelta(e.target.value)}
                    />
                    <div className="coin">
                      <p className={'pricent'}>%</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="assets-amount-block">
              <div className="assets-amount-block_wrapper">
                <div className="assets-amount-block_title">
                  <h6>Asset Amounts</h6>
                  <p>Set how many tokens you deposit into the pool</p>
                </div>
                <div className="assets-amount-block_amount">
                  <p>If you want to buy</p>
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 0) {
                        setAmount(0);
                      } else {
                        setAmount(e.target.value);
                      }
                    }}
                  />
                  <div className="assets-amount-block_amount_collection">
                    {selectedCollection?.img?.startsWith('Qm') ? (
                      <img
                        src={`https://atomichub-ipfs.com/ipfs/${selectedCollection?.img}`}
                        alt=""
                      />
                    ) : (
                      <img src={selectedCollection?.img} alt="" />
                    )}
                    {selectedCollection?.collection_name}
                  </div>
                </div>
                {selectedValue === 'LINEAR' ? (
                  <div className="assets-amount-block_alert">
                    <p>
                      you will need to deposit{' '}
                      <span>{Number(priceTotal).toFixed(8)} WAX</span> total
                    </p>
                  </div>
                ) : (
                  <div className="assets-amount-block_alert">
                    <p>
                      you will need to deposit{' '}
                      <span>{Number(priceExponTotal).toFixed(8)} WAX</span>{' '}
                      total
                    </p>
                  </div>
                )}

                <div className="assets-amount-block_scroll">
                  <div className="assets-amount-block_scroll-wrapper">
                    <p className="amount-nft">
                      Buying <span>{slideValue} NFTs</span>
                    </p>
                  </div>
                  <div className="assets-amount-block_scroll_scroller">
                    <SliderS
                      amount={amount}
                      slideValue={slideValue}
                      setSliderValue={setSliderValue}
                    />
                  </div>
                  {selectedValue === 'LINEAR' ? (
                    <div className="assets-amount-block_cost">
                      <p>
                        will cost you{' '}
                        <span>{Number(price).toFixed(8)} WAX</span>
                      </p>
                    </div>
                  ) : (
                    <div className="assets-amount-block_cost">
                      <p>
                        will cost you{' '}
                        <span>{Number(priceExpon).toFixed(8)} WAX</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="diagram-block">
            <div className="diagram-block_title">
              <h4>WAX Price per {selectedCollection?.collection_name} NFTs</h4>
              <p>Displays how your buy price goes down with each NFT bought</p>
            </div>
            {selectedValue === 'LINEAR' && (
              <LinearDiagram
                selectedOption={selectedOption}
                startPrice={startPrice}
                delta={delta}
                amount={amount}
                slideValue={slideValue}
              />
            )}
            {selectedValue === 'EXPONENTIAL' && (
              <ExponentialDiagram
                selectedOption={selectedOption}
                startPrice={startPrice}
                delta={exponDelta}
                amount={amount}
                slideValue={slideValue}
              />
            )}
          </div>
        </>
      )}

      {selectedOption === SELL && (
        <>
          <div className="count-block">
            <div className="pool-pricing-block">
              <div className="pool-pricing-block_wrapper">
                <div className="pool-pricing-block_title">
                  <h6>Pool Pricing</h6>
                  <p>Set the initial price and how your pool’s price changes</p>
                </div>
                <div className="start-price">
                  <p>Start price: </p>
                  <input
                    type="number"
                    value={startPrice}
                    onChange={(e) => setStartPrice(e.target.value)}
                  />
                  <div className="coin">
                    <img src={WaxLogo} alt="" />
                    <p>WAX</p>
                  </div>
                </div>
                <div className="bonding">
                  <p>Bonding Curve</p>
                  <select
                    name="delta"
                    value={selectedValue}
                    onChange={(e) => handleSelectChange(e.target.value)}
                  >
                    <option value="LINEAR">Linear</option>
                    <option value="EXPONENTIAL">Exponential</option>
                  </select>
                </div>
                {selectedValue === 'LINEAR' ? (
                  <div className="delta-price">
                    <p>Delta </p>
                    <input
                      type="number"
                      value={delta}
                      onChange={(e) => setDelta(e.target.value)}
                    />
                    <div className="coin">
                      <img src={WaxLogo} alt="" />
                      <p>WAX</p>
                    </div>
                  </div>
                ) : (
                  <div className="delta-price">
                    <p>Delta %</p>
                    <input
                      type="number"
                      value={exponDelta}
                      onChange={(e) => setExponDelta(e.target.value)}
                    />
                    <div className="coin">
                      <p className={'pricent'}>%</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="assets-amount-block">
              <div className="assets-amount-block_wrapper">
                <div className="assets-amount-block_title">
                  <h6>Asset Amounts</h6>
                  <p>Set how many tokens you deposit into the pool</p>
                </div>
                <div className="assets-amount-block_amount">
                  <p>If you want to sell</p>
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 0) {
                        setAmount(0);
                      } else {
                        setAmount(e.target.value);
                      }
                    }}
                  />
                  <div className="assets-amount-block_amount_collection_sell">
                    {selectedCollection}
                  </div>
                </div>
                <div className="assets-amount-block_alert">
                  {/*<p>you will need to deposit <span>/280/ WAX</span> total</p>*/}
                </div>
                <div className="assets-amount-block_scroll">
                  <div className="assets-amount-block_scroll-wrapper">
                    <p className="amount-nft">
                      Buying <span>{slideValue} NFTs</span>
                    </p>
                  </div>
                  <div className="assets-amount-block_scroll_scroller">
                    <SliderS
                      amount={amount}
                      slideValue={slideValue}
                      setSliderValue={setSliderValue}
                    />
                  </div>
                  {selectedValue === 'LINEAR' ? (
                    <div className="assets-amount-block_cost">
                      <p>
                        will receive you{' '}
                        <span>{Number(price).toFixed(8)} WAX</span>
                      </p>
                    </div>
                  ) : (
                    <div className="assets-amount-block_cost">
                      <p>
                        will receive you{' '}
                        <span>{Number(priceExpon).toFixed(8)} WAX</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="diagram-block">
            <div className="diagram-block_title">
              <h4>WAX Price per {selectedCollection} NFTs</h4>
              <p>Displays how your buy price goes down with each NFT bought</p>
            </div>
            {selectedValue === 'LINEAR' && (
              <LinearDiagram
                selectedOption={selectedOption}
                startPrice={startPrice}
                delta={delta}
                amount={amount}
                slideValue={slideValue}
              />
            )}
            {selectedValue === 'EXPONENTIAL' && (
              <ExponentialDiagram
                selectedOption={selectedOption}
                startPrice={startPrice}
                delta={exponDelta}
                amount={amount}
                slideValue={slideValue}
              />
            )}
          </div>
        </>
      )}

      {selectedOption === BOTH && (
        <>
          <div className="count-block">
            <div className="pool-pricing-block">
              <div className="pool-pricing-block_wrapper">
                <div className="pool-pricing-block_title">
                  <h6>Pool Pricing</h6>
                  <p>Set the initial price and how your pool’s price changes</p>
                </div>
                <div className="fees-amount">
                  <p>Fee amount </p>
                  <input
                    type="number"
                    value={feesAmountForTrade}
                    onChange={(e) => setFeesAmountForTrade(e.target.value)}
                  />
                  <div className="coin">
                    <p>%</p>
                  </div>
                </div>
                <div className="start-price">
                  <p>Start price: </p>
                  <input
                    type="number"
                    value={startPrice}
                    onChange={(e) => setStartPrice(e.target.value)}
                  />
                  <div className="coin">
                    <img src={WaxLogo} alt="" />
                    <p>WAX</p>
                  </div>
                </div>
                <div className="bonding">
                  <p>Bonding Curve</p>
                  <select
                    name="delta"
                    value={selectedValue}
                    onChange={(e) => handleSelectChange(e.target.value)}
                  >
                    <option value="LINEAR">Linear</option>
                    <option value="EXPONENTIAL">Exponential</option>
                  </select>
                </div>
                {selectedValue === 'LINEAR' ? (
                  <div className="delta-price">
                    <p>Delta </p>
                    <input
                      type="number"
                      value={delta}
                      onChange={(e) => setDelta(e.target.value)}
                    />
                    <div className="coin">
                      <img src={WaxLogo} alt="" />
                      <p>WAX</p>
                    </div>
                  </div>
                ) : (
                  <div className="delta-price">
                    <p>Delta</p>
                    <input
                      type="number"
                      value={exponDelta}
                      onChange={(e) => setExponDelta(e.target.value)}
                    />
                    <div className="coin">
                      <p className={'pricent'}>%</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="assets-amount-block">
              <div className="assets-amount-block_wrapper">
                <div className="assets-amount-block_title">
                  <h6>Asset Amounts</h6>
                  <p>Set how many tokens you deposit into the pool</p>
                </div>
                <div className="assets-amount-block_amount">
                  <p>Buy up to</p>
                  <input
                    type="number"
                    value={amountToBuy}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 0) {
                        setAmountToBuy(0);
                      } else {
                        setAmountToBuy(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="info-block">
                  <p>
                    Deposit WAX to buy up to {amountToBuy}{' '}
                    {selectedCollection?.collection_name}
                  </p>
                </div>
                <div className="assets-amount-block_amount">
                  <p>Sell up to</p>
                  <input
                    type="number"
                    value={amountToSell}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 0) {
                        setAmountToSell(0);
                      } else {
                        setAmountToSell(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="info-block">
                  <p>
                    Deposit {amountToSell} {selectedCollection?.collection_name}{' '}
                    to sell for WAX
                  </p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className="assets-amount-block_scroll">
                    <div className="assets-amount-block_scroll-wrapper">
                      <p className="amount-nft">
                        Buying <span>{slideValue} NFTs</span>
                      </p>
                    </div>
                    <div className="assets-amount-block_scroll_scroller">
                      <SliderS
                        amount={amountToBuy}
                        slideValue={slideValue}
                        setSliderValue={setSliderValue}
                      />
                    </div>
                    {selectedValue === 'LINEAR' ? (
                      <div className="assets-amount-block_cost">
                        <p>
                          will cost you{' '}
                          <span>{Number(willEarnSum).toFixed(8)} WAX</span>
                        </p>
                      </div>
                    ) : (
                      <div className="assets-amount-block_cost">
                        <p>
                          will cost you{' '}
                          <span>{Number(willEarnSum).toFixed(8)} WAX</span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="assets-amount-block_scroll">
                    <div className="assets-amount-block_scroll-wrapper">
                      <p className="amount-nft">
                        Selling <span>{slideValueToSell} NFTs</span>
                      </p>
                    </div>
                    <div className="assets-amount-block_scroll_scroller">
                      <SliderS
                        amount={amountToSell}
                        slideValue={slideValueToSell}
                        setSliderValue={setSliderValueToSell}
                      />
                    </div>
                    {selectedValue === 'LINEAR' ? (
                      <div className="assets-amount-block_cost">
                        <p>
                          will earn you{' '}
                          <span>{Number(willCostSum).toFixed(8)} WAX</span>
                        </p>
                      </div>
                    ) : (
                      <div className="assets-amount-block_cost">
                        <p>
                          will earn you{' '}
                          <span>{Number(willCostSum).toFixed(8)} WAX</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="diagram-block">
            <div className="diagram-block_title">
              <h4>WAX Price per {selectedCollection?.collection_name} NFTs</h4>
              <p>Displays how your buy price goes down with each NFT bought</p>
            </div>

            {selectedValue === 'LINEAR' && (
              <LinearDiagramForTrade
                startPrice={startPrice}
                delta={delta}
                amountToBuy={amountToBuy}
                amountToSell={amountToSell}
                slideValue={slideValue}
                fee={feesAmountForTrade}
                setWillCost={setWillCost}
                setWillEarn={setWillEarn}
              />
            )}
            {selectedValue === 'EXPONENTIAL' && (
              <ExponentialDiagramForTrade
                startPrice={startPrice}
                delta={exponDelta}
                amountToBuy={amountToBuy}
                amountToSell={amountToSell}
                slideValue={slideValue}
                fee={feesAmountForTrade}
                setWillCost={setWillCost}
                setWillEarn={setWillEarn}
              />
            )}
          </div>
        </>
      )}

      {selectedOption === OFFER && (
        <div className='offer-datails'>
          <div className="offer-datails_wrapper">
            <p className="offer-datails_title">
              Details
            </p>

            <div className="visibility-container">
              <div className="visibility-container-switcher">
                <p>Visibility</p>
                <div>
                  <input
                    type="radio"
                    id="public"
                    name="visibility"
                    value="Public"
                    checked={visibility === 'Public'}
                    onChange={() => handleVisibilityChange('Public')}
                  />
                  <label htmlFor="public">Public</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="private"
                    name="visibility"
                    value="Private"
                    checked={visibility === 'Private'}
                    onChange={() => handleVisibilityChange('Private')}
                  />
                  <label htmlFor="private">Private</label>
                </div>
              </div>
              <div className="visibility-container-accounts">
                {visibility === 'Private' && (
                  <>
                    <input
                      type="text"
                      placeholder="Enter verified accounts"
                      value={accountInput}
                      onChange={(e) => setAccountInput(e.target.value)}
                    />
                    <OrangeButton onClick={handleAddAccount}>Add</OrangeButton>
                  </>
                )}
              </div>
            </div>
            <div className="verified-accounts">
              {verifiedAccounts.map((account, index) => (
                <div key={index} className="verified-account-item">
                  <p>{account}</p>
                  <CloseIcon onClick={() => handleRemoveAccount(account)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ThirdStep;
