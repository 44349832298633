import React from 'react';
import { createRoot } from 'react-dom/client';  // Import createRoot from react-dom/client
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.js';
import { UALProvider } from 'ual-reactjs-renderer';
import { waxChain, waxAuthenticators } from './config/wax.config';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

const defaultState = {
  alchBalance: 0,
  waxBalance: 0,
  stakedTokens: [],
  allCollections: [],
  allUserCollections: [],
  userStakedReward: [],
  tokenInfo: [],
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'ALCH_BALANCE':
      return { ...state, alchBalance: action.payload };

    case 'WAX_BALANCE':
      return { ...state, waxBalance: action.payload };

    case 'STAKED_TOKENS':
      return { ...state, stakedTokens: action.payload };

    case 'ALL_COLLECTIONS':
      return { ...state, allCollections: action.payload };

    case 'ALL_USER_COLLECTIONS':
      return { ...state, allUserCollections: action.payload };

    case 'USER_STAKED_REWARD':
      return { ...state, userStakedReward: action.payload };

    case 'TOKEN_INFO':
      return { ...state, tokenInfo: action.payload };

    default:
      return state;
  }
};

const store = createStore(reducer);

// Use createRoot from react-dom/client
const root = document.getElementById('root');
const rootInstance = createRoot(root);

rootInstance.render(
  <Provider store={store}>
    <UALProvider
      chains={[waxChain]}
      authenticators={waxAuthenticators}
      appName={'AlchemySwap'}
    >
      <Router>
        <App />
      </Router>
    </UALProvider>
  </Provider>
);
