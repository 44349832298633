import React, { useEffect, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  ALCHInfo,
  getBalance,
  getStakedTokens,
  getUserCollections,
  getUserReward,
  getWLCollections,
  getWaxBalance,
} from './services/wax.services';
import { useDispatch } from 'react-redux';
import { UALContext } from 'ual-reactjs-renderer';

import Header from './components/Header/Header';
import MainPage from './pages/MainPage';
import CollectionsPage from './pages/CollectionsPage/CollectionsPage';
import Pool from './pages/Pool/Pool';
import CreatePool from './pages/CreatePool/CreatePool';
import CollectionPage from './pages/CollectionPage/CollectionPage';
import NotFound from './pages/NotFound/NotFound';
import PoolPageBuy from './pages/PoolPageBuy/PoolPageBuy';
import PoolPageSell from './pages/PoolPageSell/PoolPageSell';
import SwapPage from './pages/SwapPage/SwapPage';
import AllPools from './pages/AllPools/AllPools';
import PoolPageTrade from './pages/PoolPageTrade/PoolPageTrade';

import Staking from './pages/Staking/Staking';
import { ToastContainer } from 'react-toastify';

import './App.scss';
import PoolPageOffer from './pages/PoolPageOffer/PoolPageOffer';
import SwapOfferPage from './pages/SwapOfferPage/SwapOfferPage';

function App() {
  const dispatch = useDispatch();
  const { activeUser } = useContext(UALContext);

  useEffect(() => {
    if (activeUser) {
      getWaxBalance({ activeUser })
        .then((data) => {
          dispatch({ type: 'WAX_BALANCE', payload: data });
        })
        .catch((e) => console.log(e));
    }
  }, [dispatch, activeUser]);

  useEffect(() => {
    getWLCollections()
      .then((data) => {
        dispatch({ type: 'ALL_COLLECTIONS', payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (activeUser && activeUser.accountName) {
      getUserCollections(activeUser)
        .then((data) => {
          dispatch({ type: 'ALL_USER_COLLECTIONS', payload: data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dispatch, activeUser]);

  useEffect(() => {
    ALCHInfo()
      .then((data) => {
        dispatch({ type: 'TOKEN_INFO', payload: data });
      })
      .catch((e) => console.log(e));
  }, [dispatch]);

  useEffect(() => {
    getBalance({ user: activeUser?.accountName })
      .then((data) => {
        dispatch({ type: 'ALCH_BALANCE', payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch, activeUser]);

  useEffect(() => {
    if (activeUser) {
      getStakedTokens({ activeUser })
        .then((data) => {
          dispatch({ type: 'STAKED_TOKENS', payload: data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dispatch, activeUser]);

  useEffect(() => {
    if (activeUser) {
      getUserReward({ activeUser })
        .then((data) => {
          dispatch({ type: 'USER_STAKED_REWARD', payload: data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [activeUser, dispatch]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/collections" element={<CollectionsPage />} />
        <Route path="/collections/:name" element={<CollectionPage />} />
        <Route path="/collections/:name/buy/:id" element={<PoolPageBuy />} />
        <Route path="/collections/:name/sell/:id" element={<PoolPageSell />} />
        <Route
          path="/collections/:name/trade/:id"
          element={<PoolPageTrade />}
        />
         <Route
          path="/collections/:name/offer/:id"
          element={<PoolPageOffer />}
        />

        <Route path="/collections/:name/swap/buy/:id" element={<SwapPage />} />
        <Route path="/collections/:name/swap/sell/:id" element={<SwapPage />} />
        <Route
          path="/collections/:name/swap/trade/:id"
          element={<SwapPage />}
        />
         <Route
          path="/collections/:name/swap/offer/:id"
          element={<SwapOfferPage />}
        />

        <Route path="/pools" element={<AllPools />} />
        <Route path="/pool" element={<Pool />} />

        <Route path="/staking" element={<Staking />} />

        <Route path="/create" element={<CreatePool />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
