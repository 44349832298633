import React from 'react';
import './NavigationButtonsOfferPool.scss'

const NavigationButtonsOfferPool = ({ 
    currentStep, 
    handleBackClick, 
    handleNextClick, 
    selectedAssetsFirst, 
    selectedAssetsSecond, 
    handleCancel, 
    confirmSwapOfferPool,
    currentPool
}) => {
    console.log(currentPool);
    return (
        <div className="navigation-buttons">

            {currentPool && currentStep === 1 && (
                <>
                    <button onClick={handleBackClick}>Back</button>
                    <button
                        onClick={selectedAssetsFirst.length !== currentPool.pool_nfts.length ? null : handleNextClick}
                        disabled={selectedAssetsFirst.length !== currentPool.pool_nfts.length}
                        className={selectedAssetsFirst.length !== currentPool.pool_nfts.length ? 'disabled' : ''}
                    >
                        Next &gt;
                    </button>
                </>
            )}

            {currentPool && currentStep === 2 && (
                <>
                    <button onClick={handleBackClick}>Back</button>
                    <button
                        onClick={selectedAssetsSecond.length !== currentPool.owner_receiving_amount ? null : handleNextClick}
                        disabled={selectedAssetsSecond.length !== currentPool.owner_receiving_amount}
                        className={selectedAssetsSecond.length !== currentPool.owner_receiving_amount ? 'disabled' : ''}
                    >
                        Next &gt;
                    </button>
                </>

            )}

            {currentStep === 3 && (
                <>
                    <button onClick={handleBackClick}>Back</button>
                    <div className='finish-action-btns'>
                        <button onClick={handleCancel}>Cancel</button>
                        <button onClick={confirmSwapOfferPool}>Confirm Swap</button>
                    </div>
                   
                </>
            )}
        </div>
    );
};

export default NavigationButtonsOfferPool;
